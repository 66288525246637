import type { AxiosResponse } from 'axios'
import type { RedeemCodesStatsType, RedeemCodesType } from './types'

import fire from '../fire'

const redeemCodeService = {
  getRedeemCodesCount: (isUnUsed = true) =>
    fire({
      method: 'get',
      url: `/redeem_codes/stats?${isUnUsed ? 'filter=unused' : ''}`,
    }) as Promise<AxiosResponse<RedeemCodesStatsType>>,
  getRedeemCodes: (isUnUsed = true) =>
    fire({
      method: 'get',
      url: `/redeem_codes?${isUnUsed ? 'filter=unused' : ''}`,
    }) as Promise<AxiosResponse<{ data: RedeemCodesType[] }>>,
  useRedeemCode: (code: string) =>
    fire({
      method: 'post',
      url: `/redeem_codes/${code}/redemption`,
    }) as Promise<AxiosResponse<any>>,
}

export default redeemCodeService
