import camelcaseKeys from 'camelcase-keys'
import useSWR from 'swr'

import { MATERIALS_KEY } from '@/apis/constants'
import RedeemCodeService from './index'

export const useRedeemCodesCount = (isUnUsed = true) => {
  return useSWR(
    [MATERIALS_KEY, `redeem-codes`, `count`, `stats=${isUnUsed}`],
    async () => {
      const res = await RedeemCodeService.getRedeemCodesCount(isUnUsed)
      return res.data.meta.count ?? 0
    },
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    },
  )
}

export const useRedeemCodes = (isUnUsed = true) => {
  return useSWR(
    [MATERIALS_KEY, `redeem-codes`, `stats=${isUnUsed}`],
    async () => {
      const res = await RedeemCodeService.getRedeemCodes(isUnUsed)
      return camelcaseKeys(res.data, { deep: true })
    },
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    },
  )
}
